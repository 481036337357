<template>
    <div class="message">
        <div class="message-title flex-start">
            <span class="line"></span>
            <span>当前位置：工时驳回通知</span>
        </div>
        <div v-if="messageList.length>0">
            <div v-for="(item,index) in messageList" :key="index" class="message-list">
                <div class="message-item-title">驳回岗位：<span class="gray">{{item.params.postName}}</span></div>
                <div class="flex-start">
                    <div class="message-item-title">工时月份：<span class="gray">{{item.params.month}}</span></div>
                    <div class="flex-start message-item-title">工时：{{item.params.workingHours}}h <img src="@/assets/images/pc/header/edit-icon.png" alt="" @click="handleSelect(item.params)"></div>
                </div>
                <div class="reject-bottom-box">
                    <div class="time"><span class="reject-name">驳回人：{{item.params.studentName}}</span><span class="reject-time">提出时间：{{item.createTime}}</span></div>
                    <div class="detail-btn" @click="handleDetail(item)">
                        查看岗位详情 >
                    </div>
                </div>
                
            </div>
            <el-pagination
            class="page"
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="totalCount">
            </el-pagination>
        </div>
        <div v-else class="no-data">
            暂无消息
        </div>
        <div class="manhour-dialog">
			<el-dialog @close="closeManhourDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
				:visible.sync="manhourVisible">
				<div slot="title" class="dialog-header">
					<span>{{manhourTitle}}</span>
					<img @click="closeManhourDialog" src="@/assets/images/pc/common/close.png">
				</div>
				<el-form :model="addManhour" :rules="addManhourRules" ref="addManhour" class="">
					<el-form-item class="dialog-formitem" label="岗位工时:" prop="workingHour">
						<el-input type="text" class="dialog-formitem-ipt" v-model="addManhour.workingHour" placeholder="请输入该学生该月份获得的岗位工时">
						</el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer flex-item">
					<span @click="closeManhourDialog" class="cancel-btn">取 消</span>
					<span @click="submitManhourDialog" class="submit-btn">确 定</span>
				</span>
			</el-dialog>
		</div>
    </div>
</template>
<script>
import {
    siteMsgListPc
} from "@/api/pc/user";
import {
    workingHourUpdate
} from "@/api/pc/manhour";
export default {
    data(){
        return{
            messageList:[],
            pageIndex:1,
            pageSize:10,
            totalCount:0,
            manhourVisible: false,
            manhourTitle: "工时上传",
            addManhour: {},
            addManhourRules: {
                workingHour: [{
                    required: true,
                    message: "请输入该学生岗位工时",
                    trigger: "blur"
                }],
            },
        }
    },
    watch: {
        manhourVisible: {
            handler(val) {
                if (!val) {
                    this.$nextTick(() => {
                        this.$refs["addManhour"].clearValidate()
                        this.addManhour = {}
                    })
                }
            },
            deep: true
        }
    },
     mounted(){
        this.getSiteMsgList()
    },
    methods:{
        //获取消息通知列表
        getSiteMsgList(){
            var params = {
                pageIndex:this.pageIndex,
                pageSize:this.pageSize
            }
            siteMsgListPc(params).then(res=>{
                if(res.code == 0){
                    this.messageList = res.data
                    this.totalCount = res.page.totalCount*1
                }
            })
        },
        //点击跳转详情
        handleDetail(item){
            this.$router.push({
                path: "/pc/activity/jobDetail?id="+item.jumpId,
            })
        },
        //点击分页
        currentChange(e){
            this.pageIndex = e
            this.getHistoryList()
        },
        handleSelect(row){
            this.manhourVisible=true
            this.addManhour.workingHoursId = row.workingHoursId
        },
        upLoadManhour() {
            this.manhourVisible = true
        },
        closeManhourDialog() {
            this.manhourVisible = false
        },
        submitManhourDialog() {
            this.$refs["addManhour"].validate(valid => {
                if (valid) {
                    workingHourUpdate(this.addManhour).then(res=>{
                        if(res.code == 0){
                            this.manhourVisible = false
                            this.getSiteMsgList()
                            this.$message({
                                type: 'success',
                                message: '工时修改成功，已发送成功学生确认'
                            });
                        }
                    })
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.manhour-dialog .el-input__inner {
    height: 36px;
    border-radius: 0px 2px 2px 0px;
    border: 0;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
}
.line{
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #0091FF;
    margin-right:8px;
}
.flex-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.message{
    padding:24px 0;
    .message-title{
        font-weight: bold;
        font-size: 16px;
        padding-bottom:16px;
        margin-bottom:10px;
    }
    .message-list{
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding:16px 20px;
        font-size:12px;
        color:#303133;
        margin-bottom:8px;
        
        .message-item-title{
            font-size:12px;
            margin-bottom:8px;
            color:#606266;
            margin-right:20px;
            img{
                width:14px;
                height:14px;
                margin-left:8px;
                cursor: pointer;
            }
        }
        .time{
            color:#909399;
            margin-right:40px;
            .reject-time{
                display: inline-block;
                margin-left:40px;
            }
        }
        .reject-bottom-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .detail-btn{
                color:#4392FA;
                font-size:12px;
                cursor: pointer;
            }
        }
    }
}
.no-data{
    text-align: center;
    margin-top:104px;
    color: #B4BCC6;
    font-size: 14px;
}
.page{
    text-align: center;
    margin-top:40px;
}
.dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 50px;
        color: #303133;
        display: flex;
        align-items: center;
    }

    img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}

.dialog-formitem {
    width: 100%;
    display: flex;
    align-items: center;

    .dialog-formitem-ipt {
        flex: 1;
        border: 1px solid #DCDFE6;
        margin-right: 18px;
        min-width: 350px;
    }
}
.submit-btn {
		width: 72px;
		height: 33px;
		background: #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.cancel-btn {
		width: 72px;
		height: 33px;
		border: 1px solid #4392FA;
		border-radius: 2px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4392FA;
		margin-left: auto;
		margin-right: 16px;
		box-sizing: border-box;
	}
    .flex-item {
		display: flex;
		align-items: center;
	}
    /deep/.el-dialog__body{
        padding:0 20px;
    }
</style>