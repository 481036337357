import axios from '@/utils/request'

let Api = {
	manhourList: "/workingHours/list",
	verifyWorkingHour:"/workingHours/verifyWorkingHour",
	settlementWorkingHour:"/workingHours/settlementWorkingHour",
	updateWorkingHour:"/workingHours/updateWorkingHour",
	exportWorkHours:"/workingHours/export"
}
// 工时列表
export function manhourListPc(params) {
	return axios({
		method: 'get',
		url: Api.manhourList,
		params: params
	})
}
//工时审核
export function workingHourVerify(params) {
	return axios({
		method: 'put',
		url: Api.verifyWorkingHour,
		data:params
	})
}
//工时发放
export function workingHourSettlement(params) {
	return axios({
		method: 'put',
		url: Api.settlementWorkingHour,
		data:params
	})
}
//工时上传
export function workingHourUpdate(params) {
	return axios({
		method: 'put',
		url: Api.updateWorkingHour,
		data:params
	})
}
//工时导出
export function workhoursExport(params) {
	return axios({
		method: 'get',
		url: Api.exportWorkHours,
		params: params,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
	})
}